import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Close } from 'app/components/Common/Close'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Gallery } from 'app/components/GalleryRoom/Gallery'
import { styles } from 'app/theme'
import useLockedBody from 'app/utils/useLockedBody'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  images?: ImageProps[]
  label?: string
  thumbnails?: ImageProps[]
}

export const GalleryRoom = memo(function GalleryRoom({
  images,
  label,
  thumbnails,
}: Props) {
  if (!images) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  let counter = 0

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      <Wrapper>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {thumbnails ? (
          <Thumbnails>
            {thumbnails.map((thumb, index) => {
              counter = counter + 1
              return (
                <Thumb
                  key={index}
                  data-counter={counter}
                  onClick={(e) => {
                    if (!modalGalleryStatus) {
                      setLocked(true)
                      setModalGalleryStatus(true)
                    } else {
                      setLocked(false)
                      setModalGalleryStatus(false)
                    }
                    const realClickedSlide =
                      Number(e.currentTarget.getAttribute('data-counter')) - 1
                    setClickedSlide(realClickedSlide)
                    setTimeout(() => {
                      setClickedSlide(null)
                    }, 100)
                  }}
                >
                  <LazyLoadComponent>
                    <StyledImage {...thumb} />
                  </LazyLoadComponent>
                </Thumb>
              )
            })}
          </Thumbnails>
        ) : null}

        <Modal className={modalGalleryStatus ? ' open' : undefined}>
          <Close
            onClick={() => {
              setLocked(false)
              setModalGalleryStatus(false)
              setClickedSlide(0)
            }}
          />
          <Gallery
            clickedSlide={clickedSlide}
            images={images}
            isOpen={modalGalleryStatus}
          />
        </Modal>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.25rem 0;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;
`

const Label = styled.div`
  ${styles.label}
  margin-bottom: 3.75rem;

  @media (max-width: 1199px) {
    margin-bottom: 2.5rem;
  }

  @media (max-width: 992px) {
    text-align: center;
    justify-content: center;
  }

  @media (max-width: 550px) {
    text-align: left;
    justify-content: flex-start;
  }
`

const Thumbnails = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: calc(100% + 2rem);
  margin-left: -2rem;
  margin-bottom: -2.5rem;

  @media (max-width: 1199px) {
    width: calc(100% + 1.25rem);
    margin-left: -1.25rem;
    margin-bottom: -1.25rem;
  }

  @media (max-width: 550px) {
    width: calc(100% + 0.75rem);
    margin-left: -0.75rem;
    margin-bottom: -0.75rem;
  }
`

const Thumb = styled.div`
  width: calc(33.333% - 2rem);
  margin-left: 2rem;
  margin-bottom: 2.5rem;
  cursor: pointer;

  @media (max-width: 1199px) {
    width: calc(33.333% - 1.25rem);
    margin-left: 1.25rem;
    margin-bottom: 1.25rem;
  }

  @media (max-width: 992px) {
    width: calc(50% - 1.25rem);
  }

  @media (max-width: 550px) {
    width: calc(50% - 0.75rem);
    margin-left: 0.75rem;
    margin-bottom: 0.75rem;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  aspect-ratio: 417/473;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`
